import React from "react"
import { Tween, Timeline } from "react-gsap"
import Img from "gatsby-image"

import Play from "../images/play.svg"
import Anchorlink from "./anchorlink"
import Headline from "../images/h1.svg"

const Header = props => (
  <header id="top">
    <div className="header">
      <div className="header__content">
        <Tween from={{ y: "100px" }} duration={1}>
          <img className="header__headline" src={Headline}></img>
        </Tween>
        <Tween from={{ y: "100px" }} delay="0.1" duration={1}>
          <p>
            {" "}
            Mein Name ist Samira Blessing. Ich bin Hochzeitssängerin und ich
            werde deinen „schönsten Tag“ zu einem unvergesslichen Erlebnis
            machen.
          </p>
        </Tween>

        <Anchorlink offset={100} href="/#portfolio" class="play-button">
          <img src={Play}></img>
          <p>Mehr erfahren</p>
        </Anchorlink>
      </div>
    </div>
  </header>
)

export default Header
